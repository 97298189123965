import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withModifiers as _withModifiers, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form-row mb-3" }
const _hoisted_2 = { class: "col col-6" }
const _hoisted_3 = {
  class: "my-1 mr-2",
  for: "courseName"
}
const _hoisted_4 = { class: "col col-6" }
const _hoisted_5 = { class: "form-row mb-3" }
const _hoisted_6 = { class: "col col-6" }
const _hoisted_7 = {
  class: "my-1 mr-2",
  for: "courseDesc"
}
const _hoisted_8 = { class: "col col-6" }
const _hoisted_9 = { class: "form-row mb-3" }
const _hoisted_10 = { class: "col col-6" }
const _hoisted_11 = {
  class: "my-1 mr-2",
  for: "langFrom"
}
const _hoisted_12 = { class: "col col-6" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "form-row mb-3" }
const _hoisted_15 = { class: "col col-6" }
const _hoisted_16 = {
  class: "my-1 mr-2",
  for: "langTo"
}
const _hoisted_17 = { class: "col col-6" }
const _hoisted_18 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "AddCourseModal",
    ref: "modal"
  }, {
    buttonText: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("messages.Create course")), 1)
    ]),
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t("messages.Create course")), 1)
    ]),
    modalBody: _withCtx(() => [
      _createElementVNode("form", {
        ref: "form",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("messages.course name")), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: "form-control",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newCourse.name = $event)),
              id: "courseName",
              required: ""
            }, null, 512), [
              [_vModelText, _ctx.newCourse.name]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("messages.Description:")), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("textarea", {
              class: "form-control",
              id: "courseDesc",
              style: {"min-height":"2rem"},
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.newCourse.description = $event))
            }, null, 512), [
              [_vModelText, _ctx.newCourse.description]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("messages.source language")), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _withDirectives(_createElementVNode("select", {
              class: "custom-select my-1 mr-sm-2",
              id: "langFrom",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.newCourse.languageFrom = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: language.name,
                  value: language.name
                }, _toDisplayString(language.fullname), 9, _hoisted_13))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.newCourse.languageFrom]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("messages.target language")), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            _withDirectives(_createElementVNode("select", {
              class: "custom-select my-1 mr-sm-2",
              id: "langTo",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.newCourse.languageTo = $event))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languages, (language) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: language.name,
                  value: language.name
                }, _toDisplayString(language.fullname), 9, _hoisted_18))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.newCourse.languageTo]
            ])
          ])
        ])
      ], 544)
    ]),
    modalFooter: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.addCourse && _ctx.addCourse(...args))),
        class: "btn btn-primary"
      }, _toDisplayString(_ctx.$t("messages.Create course")), 1)
    ]),
    _: 1
  }, 512))
}