
import Dashboard from '@/components/common/Dashboard.vue';
import { defineComponent } from 'vue';
import AddCourseModal from '@/components/teacher/AddCourseModal.vue';
import DeleteCourseModal from '@/components/teacher/DeleteCourseModal.vue';
import { Course, errorPrepend } from '@/models';
import { showError } from '@/sweetalert2/templates.ts';

export default defineComponent({
  components: { DeleteCourseModal, AddCourseModal, Dashboard },
  methods: {
    async addCourse (course: Course) {
      try {
        await Course.create(course);
      } catch (e) {
        await showError(this.$t("messages.no course add") + errorPrepend());
      }
      await this.$store.dispatch('getCourses');
      await this.$store.dispatch('setSideNav');
    },
    async deleteCourses (courseIds: number[]) {
      if (courseIds.length === 0) {
        return;
      }
      try {
        await Promise.all(courseIds.map(id => Course.delete(id)));
      } catch (e) {
        await showError(this.$t("messages.course error del") + errorPrepend());
      }
      await this.$store.dispatch('getCourses');
      await this.$store.dispatch('setSideNav');
    }
  }
});
