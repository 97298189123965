
import { defineComponent } from 'vue';
import { Course, LanguageShort } from '@/models';
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal.vue';

export default defineComponent({
  components: { Modal },
  data () {
    return {
      newCourse: {
        id: -1,
        name: '',
        description: '',
        languageFrom: LanguageShort.de,
        languageTo: LanguageShort.en,
        studentIds: [],
        classIds: []
      } as Course
    };
  },
  emits: ['addCourse'],
  computed: {
    ...mapState({ languages: 'languages' })
  },
  methods: {
    addCourse () {
      const form = this.$refs.form as HTMLFormElement;
      if (!form.reportValidity()) {
        return;
      }
      this.$emit('addCourse', this.newCourse);
      this.clearInputs();
      (this.$refs.modal as typeof Modal).closeModal();
    },
    clearInputs () {
      this.newCourse = {
        name: '',
        description: '',
        languageFrom: LanguageShort.de,
        languageTo: LanguageShort.en
      } as Course;
    }
  }
});
