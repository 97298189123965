
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  data () {
    return {
      checkedBoxes: [],
      confirm: false,
      modal: (this.$refs?.modal as HTMLDivElement)?.classList
    };
  },
  computed: {
    ...mapState({
      courses: 'courses'
    })
  },

  emits: ['deleteCourses'],
  methods: {
    deleteCourses () {
      const form = this.$refs.form as HTMLFormElement;
      const dismissButton = this.$refs.dismissButton as HTMLButtonElement;
      if (!form.reportValidity() || this.checkedBoxes.length <= 0) {
        return;
      }
      this.$emit('deleteCourses', this.checkedBoxes);
      this.clearInputs();
      dismissButton.click();
    },
    clearInputs () {
      this.checkedBoxes = [];
      this.confirm = false;
    }
  }
});
